import { FC, memo, useCallback } from 'react'

import { Text, Button } from '@cash-me/react-components'
import { useRouter } from 'next/router'

import { Carousel } from 'components/carousel'
import { Grid, Col } from 'components/grid'
import { pushDataLayer } from 'utils/push-data-layer'

import { HowWorksProps } from './how-works.model'
import * as S from './styles'

export const HowWorks: FC<HowWorksProps> = memo(({ medium = false, children, cards, onClick }) => {
  const router = useRouter()

  const handleDataLayer = useCallback(
    (index: number) => {
      const cleanedPath = router.asPath?.replaceAll('-', ' ').slice(1)
      const dataLayerAction = cleanedPath ? cleanedPath.charAt(0).toUpperCase() + cleanedPath.slice(1) : 'Como funciona'

      pushDataLayer({
        category: 'Slider',
        action: dataLayerAction,
        label: `Como funciona - Slide ${cards[index].title}`
      })
    },
    [cards, router.asPath]
  )

  return (
    <S.Section $bg={medium ? 'medium' : 'default'}>
      <Grid col={{ phone: 1, tablet: 12 }} gap={{ phone: 'xxs' }}>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ desktop: 2 }}>
          <S.Infos>{children}</S.Infos>
        </Col>

        <Col col={{ tablet: 6, desktop: 6 }} start={{ tablet: 7, desktop: 7 }}>
          <S.RightSideWrapper medium={medium}>
            <S.CarouselBg />
            <S.CarouselWrapper>
              <Carousel onChangeIndex={handleDataLayer} navigation={true}>
                {cards.map(({ id, title, description }) => (
                  <S.Card key={id}>
                    <S.CardCounter>
                      <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                          opacity="0.24"
                          cx="27.5783"
                          cy="27.765"
                          r="25.9479"
                          stroke="#00BFFF"
                          strokeWidth="2.07583"
                        />
                        <path
                          d="M27.7077 1.81711C32.1253 1.81711 36.4689 2.95625 40.3232 5.12557C44.1775 7.29489 47.4135 10.4218 49.7217 14.2073C52.0298 17.9927 53.333 22.31 53.5064 26.7463C53.6798 31.1826 52.7178 35.5893 50.7122 39.5451C48.7067 43.5009 45.7248 46.8734 42.0518 49.3399C38.3787 51.8065 34.1374 53.2846 29.7334 53.6329C25.3295 53.9813 20.9103 53.1881 16.8985 51.3294C12.8868 49.4707 9.41664 46.6086 6.82007 43.0168"
                          stroke="#00BFFF"
                          strokeWidth="2.07583"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {id}
                    </S.CardCounter>
                    <Text as="h3" size="heading4" weight="medium">
                      {title}
                    </Text>
                    <Text size="body2" color="var(--colors-neutral-text-weak)">
                      {description}
                    </Text>
                  </S.Card>
                ))}
              </Carousel>
            </S.CarouselWrapper>

            {onClick && (
              <Button size="lg" variants="text-only" onClick={onClick}>
                Simular agora
              </Button>
            )}
          </S.RightSideWrapper>
        </Col>
      </Grid>
    </S.Section>
  )
})

HowWorks.displayName = 'HowWorks'
